@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply flex flex-col h-full;
}

body {
  @apply flex flex-col grow;
}

.fui-Field__validationMessage {
  @apply flex items-center;
}
